/* eslint-disable no-unused-vars */
import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoadingScreen from "src/components/Common/LoadingScreen";
import DashboardLayout from "src/layouts/DashboardLayout";

const Dashboard = lazy(() =>
  import("src/views/viewAdministrador/dashboardAdmin/components/GraphicsTab")
);
const Residente = lazy(() => import("src/views/viewAdministrador/residente"));
const Notificaciones = lazy(() =>
  import("src/views/viewAdministrador/notificaciones")
);
const Votacion = lazy(() => import("src/views/viewAdministrador/votacion"));
const ReporteIngresosVsEgresos = lazy(() =>
  import("src/views/viewAdministrador/reportes/ingVsEgr")
);
const ReporteCuentasporCobrar = lazy(() =>
  import("src/views/viewAdministrador/reportes/cuentasporcobrar")
);
const ReporteIngresos = lazy(() =>
  import("src/views/viewAdministrador/reportes/ingresos")
);
const ReporteEgresos = lazy(() =>
  import("src/views/viewAdministrador/reportes/egresos")
);
const ReporteCartera = lazy(() =>
  import("src/views/viewAdministrador/reportes/cartera")
);
const ReporteCarteraUsuario = lazy(() =>
  import("src/views/viewAdministrador/reportes/carteraPorUsuario")
);
const ReporteHistoricoCartera = lazy(() =>
  import("src/views/viewAdministrador/reportes/historicoCartera")
);
const ReporteConsolidado = lazy(() =>
  import("src/views/viewAdministrador/reportes/consolidado")
);
const ReporteFinanciero = lazy(() =>
  import("src/views/viewAdministrador/reportes/financiero")
);

const Directiva = ({ match }) => (
  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
        <Route
          exact
          path={`${match.url}/dashboard`}
          render={(props) => <Dashboard {...props} />}
        />
        <Route
          exact
          path={`${match.url}/residentes`}
          render={(props) => <Residente {...props} />}
        />
        <Route
          path={`${match.url}/votacion`}
          exact
          render={(props) => <Votacion {...props} />}
        />
        <Route
          path={`${match.url}/notificaciones`}
          exact
          render={(props) => <Notificaciones {...props} />}
        />
        <Route
          path={`${match.url}/reportes/ingvsegr`}
          exact
          render={(props) => <ReporteIngresosVsEgresos {...props} />}
        />
        <Route
          path={`${match.url}/reportes/cuentasporcobrar`}
          exact
          render={(props) => <ReporteCuentasporCobrar {...props} />}
        />
        <Route
          path={`${match.url}/reportes/ingresos`}
          exact
          render={(props) => <ReporteIngresos {...props} />}
        />
        <Route
          path={`${match.url}/reportes/egresos`}
          exact
          render={(props) => <ReporteEgresos {...props} />}
        />
        <Route
          path={`${match.url}/reportes/cartera`}
          exact
          render={(props) => <ReporteCartera {...props} />}
        />
        <Route
          path={`${match.url}/reportes/carteraUsuario`}
          exact
          render={(props) => <ReporteCarteraUsuario {...props} />}
        />
        <Route
          path={`${match.url}/reportes/historicoCartera`}
          exact
          render={(props) => <ReporteHistoricoCartera {...props} />}
        />
        <Route
          path={`${match.url}/reportes/consolidado`}
          exact
          render={(props) => <ReporteConsolidado {...props} />}
        />
        <Route
          path={`${match.url}/reportes/financiero`}
          exact
          render={(props) => <ReporteFinanciero {...props} />}
        />
      </Switch>
    </Suspense>
  </DashboardLayout>
);

export default Directiva;
