import { Avatar, Button, Chip } from '@material-ui/core'
import React from 'react'
import * as XLSX from 'xlsx'

export const ExportDataButton = ({
  type = 'chip', label = 'exportar datos', fileTitle = 'Reporte',
  data = [], extraProps = {}
}) => {
  const exportDataToFile = () => {
    const ws1 = XLSX.utils.json_to_sheet(data);
    /* add to workbook */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, fileTitle);
    /* generate an XLSX file */
    XLSX.writeFile(wb, `MiCasita-${fileTitle}.xlsx`);
  }
  if (type === 'chip') {
    return (
      <Chip
        avatar={<Avatar alt="excel-icon" src="/assets/img/excel.png" />}
        label={label}
        variant="outlined"
        color="primary"
        style={{ color: 'green' }}
        onClick={exportDataToFile}
        {...extraProps}
      />
    )
  }
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={exportDataToFile}
      style={{ backgroundColor: '#008000' }}
      component="span"
      {...extraProps}
    >
      {label}
    </Button>
  )
}

export default ExportDataButton
