import React from "react"
import {
  // eslint-disable-next-line no-unused-vars
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  // eslint-disable-next-line no-unused-vars
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core"
import {
  Close as CloseIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  CropFree,
} from "@material-ui/icons"

/**
 * @typedef CustomProps
 * @type {object}
 * @property {string} [title] texto para el header del modal
 * @property {String} [textCancel] by default "Cancelar"
 * @property {String} [textConfirm] by default "Confirmar"
 * @property {Function} [handleCancel] event click for cancel button by default "onClose" property
 * @property {Function} [handleConfirm] event click for confirm button by default any
 * @property {Boolean} [withTransition] true to dialog transition effect, by default false
 * @property {Boolean} [showActions] default "true", show action buttons
 * @property {Boolean} [showHeader] default "false", show custom DialogHeader
 * @property {SlideProps} [transitionProps] transitionProps, it will be applied if "withTransition" is set to true
 */

/**
 * @type React.FC<(DialogProps|CustomProps)>
 * Custom Dialog que requiere solo el contenido del Dialog
 * @returns JSX.Element
 */
const CustomDialog = ({
  title = "Mi casita",
  handleCancel,
  handleConfirm,
  textCancel = "Cancelar",
  textConfirm = "Confirmar",
  withTransition = false,
  showActions = true,
  showHeader = false,
  showQrOption = false,
  handleQr,
  transitionProps = {},
  children,
  onClose,
  ...rest
}) => (
  <Dialog
    TransitionComponent={
      withTransition ? <Transition {...transitionProps} /> : undefined
    }
    onClose={onClose}
    {...rest}
  >
    {showHeader ? (
      <Header title={title} onClose={onClose} />
    ) : (
      <DialogTitle>{title}</DialogTitle>
    )}
    <DialogContent dividers>{children}</DialogContent>
    {showActions && (
      <DialogActions>
        {showQrOption && (
          <Button
            onClick={() => {
              handleQr()
            }}
            variant="outlined"
            color="primary"
          >
            <CropFree className="me-2" />
            Ver Qr
          </Button>
        )}
        <Button
          onClick={handleCancel || onClose}
          variant="outlined"
          color="primary"
        >
          <CloseIcon className="me-2" />
          {handleCancel ? textCancel : "Cerrar"}
        </Button>
        {handleConfirm && (
          <Button onClick={handleConfirm} variant="contained" color="primary">
            <CheckCircleOutlineIcon className="me-2" />
            {textConfirm}
          </Button>
        )}
      </DialogActions>
    )}
  </Dialog>
)

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

const Header = ({ title, onClose }) => (
  <AppBar className="position-relative">
    <Toolbar>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h6" className="d-flex flex-grow-1">
        {title}
      </Typography>
    </Toolbar>
  </AppBar>
)

export default CustomDialog
