import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplex,
  selectComplex,
  getRemoteUsers,
  getRemoteUnits,
  selectGarita,
} from "src/redux/slices/complexSlice";
import { InputAdornment, Typography } from "@material-ui/core";
import { AccountBalance } from "@material-ui/icons";

const ConjuntosSelect = () => {
  const conjuntosId = useSelector((state) => state.auth.user.complexs);
  const selectConjunto = useSelector((state) => state.complex.selectComplex);
  const dispatch = useDispatch();
  const [selectedConjunto, setSelectedConjunto] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (conjuntosId.length > 0 && isMounted) {
      handleLanguageMenuClose(conjuntosId[selectConjunto], selectConjunto);
    }
    return () => {
      isMounted = false;
    };
  }, [conjuntosId]);

  const handleLanguageMenuClose = async (conjunto, index = 0) => {
    if (conjunto) {
      dispatch(getComplex(conjunto.uid));
      dispatch(getRemoteUsers(conjunto.uid));
      dispatch(getRemoteUnits(conjunto.uid));
      dispatch(selectComplex(index));
      dispatch(selectGarita(null));
      setSelectedConjunto(conjunto);
    }
  };

  return (
    conjuntosId && (
      <div>
        {conjuntosId.length > 1 ? (
          <Autocomplete
            options={conjuntosId}
            getOptionLabel={(option) => option.nombre}
            noOptionsText="No se han encontrado conjuntos"
            value={selectedConjunto}
            style={{ width: 300 }}
            onChange={(event, newValue) => {
              const index = conjuntosId.findIndex(
                (item) => item?.uid === newValue?.uid
              );
              handleLanguageMenuClose(newValue, index);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountBalance style={{ fontSize: "1.3em" }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        ) : (
          <Typography variant="body1" color="primary">
            {selectedConjunto?.nombre}
          </Typography>
        )}
      </div>
    )
  );
};

export default ConjuntosSelect;
