/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { CustomDialog } from "src/components/Common";
import { deleteresva } from "src/views/viewAdministrador/areasComunales/reservaAreasComunales/services/firestore";
import { useSnackbar } from "notistack";
import { sendNotification } from "src/config/sendNotification";
import useUsersByUnits from "src/hooks/useUsersByUnits";
import moment from "moment";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useSelector } from "react-redux";

const EliminarReserva = ({ conjuntoId, areaId, reserva, setReservas }) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { usersByUnit } = useUsersByUnits();
  const conjunto = useSelector((state) => state.complex.complex);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmCancel = async () => {
    await deleteresva(conjuntoId, areaId, reserva.id);
    enqueueSnackbar(
      "Reserva rechazada, se envió la notificación al residente.",
      {
        variant: "success",
      }
    );
    const tokens =
      usersByUnit.find((doc) => doc.idUser === reserva?.Usuario)?.idUser || [];
  
    sendNotification(
      'Reserva rechazada',
      `Su reserva para ${moment(reserva.Inicio.toDate()).format(
        "DD-MM-YYYY"
      )} ha sido rechazada comuníquese con el administrador`,
      [tokens],
      conjunto
    );
    handleClose();
    setReservas((prevReservas) =>
      prevReservas.filter((r) => r.id !== reserva.id)
    );
  };

  return (
    <>
      <Tooltip title="Eliminar reserva">
        <Button onClick={handleOpen}>
          <DeleteForeverIcon />
        </Button>
      </Tooltip>
      <CustomDialog
        open={open}
        onClose={handleClose}
        title="Confirmar acción de reserva"
        handleConfirm={confirmCancel}
      >
        <Typography>
          ¿Está seguro que desea <b>eliminar/cancelar</b> la reserva?
        </Typography>
      </CustomDialog>
    </>
  );
};

export default EliminarReserva;
