import React, { useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment/moment";
import { sendNotification } from "src/config/sendNotification";
import { useSnackbar } from "notistack";
import * as FirestoreService from "./services/firestore";

const AprobarAllReservas = ({
  conjunto,
  reservas,
  setReservas,
  usersByUnit,
}) => {
  const [loadingAprobar, setLoadingAprobar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenDialog = () => {
    setOpenConfirm(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirm(false);
  };

  const handleAprobarTodas = async () => {
    setLoadingAprobar(true);
    setLoading(true);
    try {
      await Promise.all(
        reservas
          .filter(
            (reserva) => reserva.Fin.toDate() > new Date() && !reserva.Aprobada
          )
          .map(async (reserva) => {
            await FirestoreService.updateresv(
              conjunto.id,
              reserva.areaId,
              reserva.id,
              { Aprobada: true }
            );
            const { tokens } =
              usersByUnit.find((doc) => doc.idUser === reserva.Usuario)
                ?.tokens || [];
            sendNotification(
              `Reserva Aprobada`,
              `¡Su reserva con fecha ${moment(reserva.Inicio.toDate()).format(
                "DD-MM-YYYY"
              )} ha sido aprobada!`,
              tokens
            );
          })
      );
      setReservas(
        reservas.filter(
          (reserva) => reserva.Fin.toDate() < new Date() && !reserva.Aprobada
        )
      );
      enqueueSnackbar(
        "Todas las reservas han sido aprobadas y se enviaron las notificaciones a los residentes.",
        {
          variant: "success",
        }
      );
    } catch {
      enqueueSnackbar("Hubo un error al aprobar las reservas.", {
        variant: "error",
      });
    }
    setLoadingAprobar(false);
    setLoading(false);
    setOpenConfirm(false);
  };

  return (
    <>
      <Button size="small" onClick={handleOpenDialog}>
        Aprobar todo
      </Button>
      <Dialog
        open={openConfirm}
        onClose={handleCloseDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Aprobar todas las reservas
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            </Box>
          ) : (
            <DialogContentText>
              ¿Esta seguro que desea <b>aprobar todas</b> las reservas?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseDialog}
            color="primary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleAprobarTodas()}
            disabled={loadingAprobar}
            color="primary"
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AprobarAllReservas;
